<template>
  <div>
    <div class="hide-content"></div>
    <div class="error-modal">
      <div class="general-popup">
        <h5 v-if="canShowErrorCode">
          {{ $t("error_modal.title") }}<br />
          {{ error }}
        </h5>

        <img src="/hero-pics/sad.svg" class="hero" />
        <h3 v-html="errorText"></h3>

        <p class="error-msg" v-html="solveText" v-if="!primary"></p>
      </div>
      <LongButton
        variant="primary"
        class="rect-borders mt-20 w-90"
        v-if="!noButton"
        @click="emitClose"
        >OK</LongButton
      >
    </div>
  </div>
</template>

<script>
import LongButton from "@/components/LongButton";

export default {
  data() {
    return {};
  },
  props: {
    error: {
      type: String,
      default: null,
    },
    out_of_range: {
      type: Boolean,
      default: false,
    },
    noButton :{
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
  mounted() {
    if (!this.isMobile) {
      this.$store.commit('desktop/setInfoPopupVariant', 'fatal_error');
      this.$emit('close');
      return;
    }

    if (!this.error) this.error = this.$t(`cafe_closed.title`);

    this.toggleGlobalScrolling(true);
  },
  beforeDestroy() {
    this.toggleGlobalScrolling(false);
  },
  computed: {
    canShowErrorCode() {
      return !this.out_of_range && !this.primary;
    },
    errorText() {
      if (this.out_of_range) {
        return this.$t(`geo.fail_titles.out_of_range`);
      }

      if (this.primary) {
        return this.error;
      }
      
      return this.$t("error_modal.text");
    },
    solveText() {
      if (this.out_of_range) {
        return this.$t('error_modal.change_address');
      }

      return this.$t("error_modal.solve");
    }
  },
  components: {
    LongButton,
  },
};
</script>

<style scoped>
p,
h3 {
  margin: 0;
  padding: 0;
}

.mt-20 {
  margin-top: 2.5vh;
}

.w-90 {
  width: 90%;
}

.error-modal {
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 11;
  padding: 20px 0 25px;
  position: absolute;
}

img.hero {
  height: 16vh;
  width: auto;
}

.error img {
  height: 70px;
}

p.error-msg {
  color: #908f8d;
}

p {
  padding-top: 10px;
}

h5 {
  color: #a4a3a1;
  text-align: center;
  font-size: 1.7vh;
}

h3 {
  color: #e2223b;
  padding-top: 2.2vh;
  font-size: 2.3vh;
}

.hide-content {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
  overflow: hidden;
  backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  top: 0;
  left: 0;
}
</style>