import BaseAPI from '../base'

function prepare(data, mode, from_marketplace, language) {
    return BaseAPI.sendPost(`preorder/prepare`, {
        link: data,
        mode: mode || 'delivery',
        from_marketplace,
        language
    });
}

function create(data) {
    return BaseAPI.sendPost(`preorder/create`, data);
}

function edit(id, client, takeaway_date) {
    return BaseAPI.sendPost(`preorder/edit`, {
        preorder_id: id,
        name: client.name,
        phone: client.phone,
        address: client.address,
        takeaway_date
    })
}

function checkGeolocation(cafe_id, lat, lon) {
    return BaseAPI.sendPost(`preorder/geolocation/${cafe_id}`, {
        lat,
        lon
    });
}

function setPayMethod(preorder_id, pay_method) {
    return BaseAPI.sendPost(`preorder/pay_method`, {
        preorder_id,
        pay_method
    });
}

function lookup(link, from_marketplace) {
    return BaseAPI.sendPost(`preorder/lookup`, {
        link,
        from_marketplace
    });
}

function sync(id) {
    return BaseAPI.sendPost(`preorder/sync/${id}`);
}

function checkDeliveryAddress(cafe_id, address) {
    return BaseAPI.sendPost(`preorder/check_delivery_address`, {
        cafe_id,
        address
    });
}

function estimateDelivery(data) {
    return BaseAPI.sendPost(`preorder/estimate_delivery`, data);
}

function getWebStatus(id) {
    return BaseAPI.sendGet(`preorder/web_status/${id}`);
}

function cancelPreorder(id, reason) {
    return BaseAPI.sendPost(`preorder/cancel/${id}`, {
        reason
    });
}

function estimatePackaging(data) {
    return BaseAPI.sendPost(`preorder/estimate_packaging`, data);
}

export default {
  prepare,
  create,
  edit,
  checkGeolocation,
  setPayMethod,
  lookup,
  sync,
  checkDeliveryAddress,
  getWebStatus,
  cancelPreorder,
  estimateDelivery,
  estimatePackaging,
};