<template>
  <div>
    <OrderLoader v-if="!error" />
    <PandaFatalError :error="error" v-if="error" />
  </div>
</template>

<script>
import OrderLoader from "@/components/OrderLoader.vue";
import PandaFatalError from "@/components/modals/PandaFatalError.vue";
import PreorderAPI from "@/api/preorder";

import OrderSaver from "@/services/ordersaver";
import InsideSaver from "@/services/order/saver.js"

import MarketplaceService from "@/services/marketplace"

import OrderAPI from "@/api/order";

export default {
  data() {
    return {
      error: null,
    };
  },
  mounted() {
    const mode = this.$route.params.mode || "delivery";

    const id = this.$route.params.id;
    const link = this.$route.params.link;

    if (mode == "inside") {
      OrderAPI.sync(id)
        .then((data) => {
          InsideSaver.save(data.order);

          this.$router.replace({
            name: 'new_order_code',
            params: {
              code: link,
            },
          });
        })
        .catch((error) => {
          this.error = error;
        });
    } else {
      PreorderAPI.sync(id)
        .then((data) => {
          OrderSaver.set(mode, data.preorder);

          MarketplaceService.set(link, false);

          this.$router.replace({
            name: mode,
            params: {
              code: link,
            },
          });
        })
        .catch((error) => {
          this.error = error;
        });
    }
  },
  components: {
    PandaFatalError,
    OrderLoader,
  },
};
</script>

<style>
</style>