import moment from 'moment'

function isPresent(mode, cafe_id) {
    const v = window.localStorage.getItem(`saved:${mode}`) != null;

    if (v) {
        const preorder = get(mode);

        const now = moment();
        const then = moment(preorder.created_at);

        if (Math.abs(now.diff(then, 'minutes')) > 60) {
            clear();
            return false;
        }

        if (`${preorder.cafe_id}` != `${cafe_id}`) {
            clear();
            return false;
        }

        return true;
    } else {
        return false;
    }
}

function set(mode, obj) {
    window.localStorage.setItem(`saved:${mode}`, JSON.stringify(obj));
}

function get(mode) {
    return JSON.parse(window.localStorage.getItem(`saved:${mode}`));
}

function clear(mode) {
    window.localStorage.removeItem(`saved:${mode}`);
}

export default {
    isPresent,
    set,
    get,
    clear
}