import moment from 'moment'

const STORAGE_KEY = 'saved_order';

function save(data) {
    console.log(`save `, data);
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
}

function isActual(target) {
    const raw = window.localStorage.getItem(STORAGE_KEY);

    if (!raw) {
        return false;
    }

    const order = JSON.parse(raw);

    const now = moment();
    const max_date = moment(order.date).add(12, 'hours');

    if (now.isAfter(max_date)) {
        return false;
    }

    if (order.cafe_id != target) {
        return false;
    }

    return true;
}

function clear() {
    console.log(`clear`);
    window.localStorage.removeItem(STORAGE_KEY);
}

function load() {
   const raw = window.localStorage.getItem(STORAGE_KEY);

   if (!raw) return {};

   return JSON.parse(raw);
}

export default {
    load,
    clear,
    save,
    isActual
}